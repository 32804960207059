import React from 'react'
import { graphql } from 'gatsby'

// Components
import {
  TitleDefault,
  TitleAlt,
  BannerDefault,
  ContentDefault,
  BoxDefault,
} from 'components/Elements'
import SEO from 'components/SEO'
import Layout from 'components/Layout'
import GravityForm from 'components/GravityForm'

// Language
import { getLanguage } from 'services/language'

// Styles
import 'styles/templates/DemoTemplate.scss'

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      title
      path
      content
      acf {
        backgroundImage: background_image {
          localFile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 800) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
              fixed(width: 800, toFormat: JPG) {
                src
              }
            }
          }
        }

        title: banner_title
        subtitle: banner_subtitle
        description: banner_description
      }
      yoast_meta {
        name
        content
        property
      }
    }
  }
`

const Page = ({
  data: {
    page: {
      path,
      acf: { backgroundImage, title, subtitle, description, formId },
      yoast_meta,
    },
  },
}) => {
    const language = getLanguage()

  return (
    <Layout backgroundImage={backgroundImage}>
      <SEO yoast={{ meta: yoast_meta }} pathname={path} image={backgroundImage.localFile.childImageSharp.fixed.src} article />
      <div className="demo-template">
        <BannerDefault className="py-5">
          <div className="py-4">
            <TitleAlt>{subtitle}</TitleAlt>
            <TitleDefault asH1 className="pl-lg-5">
              {title}
            </TitleDefault>
            <ContentDefault className="mt-4">
              <div
                dangerouslySetInnerHTML={{ __html: description }}
              />
            </ContentDefault>
          </div>
        </BannerDefault>

        <div className="container pb-5">
          <BoxDefault>
            <ContentDefault className="py-4 px-3 px-lg-5">
                <GravityForm id={language === 'nl_NL' ? 3 : 4} />
            </ContentDefault>
          </BoxDefault>
        </div>
      </div>
    </Layout>
  )
}

export default Page
